import { TextField, Typography, Box, Link, Button, Alert, Modal } from '@mui/material';
import React from 'react';
import { SpacerLayout } from '../components/Layout';
import ReCAPTCHA from "react-google-recaptcha";
import { useState } from "react";


// Input parameters 
//       If testing, remember to revert setting back to default debug=false and disable_reCAPTCHA=false

// This flag allows the submit button to be visible without reCAPTCHA verification. Only enable for local testing purposes
const forceVisibleSubmit = false
//Debug flag to print out additional debug messages to browser console. Only enable for local testing purposes
const debug = false


//Google reCAPTCHA site key
// Normal/Non-invisible 
//const reCAPTCHA_Site_Key = "6LfuYbEnAAAAAACNqPq18GfYKXoB3IdymG3B28SZ"
// Invisible Key
const reCAPTCHA_Site_Key = "6LchIcYnAAAAACEOi0jBonkBNKu_Wz2Qs5jCHKVp"
//AWS Lambda API Endpoint
const Lambda_Endpoint = process.env.LAMBDA_ENDPOINT_INTAKE;






// Contact form object that creates a contact form on webpage and sends request out to AWS Lambda endpoint
function patientIntakeForm() {

  //Variables for contact form field
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [address, setAddress] = useState("")
  const [phone, setPhone] = useState("")
  const [prefContact, setPrefContact] = useState("")
  const [emergContact, setEmergContact] = useState("")

  //reCAPTCHA reference
  const recaptchaRef = React.createRef()

  //Variables for hiding submit button, set to forceVisibleSubmit var for debug purpose
  const [hide, setHide] = useState(forceVisibleSubmit)

  //Var for only allowing submit button for first submit
  const [successfulSubmit, setSuccessfulSubmit] = useState(false)

  //Vars for Contact form labels, will change these after first successful submit
  const [nameLabel, setNameLabel] = useState("Name");
  const [emailLabel, setEmailLabel] = useState("Email");

  const [addressLabel, setAddressLabel] = useState("Address")
  const [phoneLabel, setPhoneLabel] = useState("Phone Number")
  const [prefContactLabel, setPrefContactLabel] = useState("Preferred Contact")
  const [emergContactLabel, setEmergContactLabel] = useState("Emergency Contact")

  //Vars for Alert/Modal: info bar appears with information about the contact form send
  const [openModal, setOpenModal] = useState(false)
  const [alertContent, setAlertContent] = useState('Sending...')
  const [severityVal, setSeverityVal] = useState('info')
  const handleModalClose = () => setOpenModal(false)
  const handleClick = () => { setHide(prev => !prev) }


  //Function for Alert/Modal when error occurs
  function setAlertSeverity_Error() {
    setAlertContent("Apologies, looks like there was an issue! Please try emailing us at justin@tensegritymarin.com instead.")
    setSeverityVal('error')
  }

  //Function for Alert/Modal when fetch is successful. Also sets contact form to "Message Sent" and will permanently disable the submit button until refreshed. This is to prevent excessive contact form sends.
  function setAlertSeverity_Good_Reset() {
    setAlertContent("Message Sent!")
    setSeverityVal('success')
    setName("")
    setEmail("")

    setAddress("")
    setPhone("")
    setPrefContact("")
    setEmergContact("")

    setHide(prev => !prev)
    setSuccessfulSubmit(true)
    setNameLabel("")
    setEmailLabel("")

    setAddressLabel("")
    setPhoneLabel("")
    setPrefContactLabel("")
    setEmergContactLabel("Message Sent!")

    if (debug == true) { console.log("DEBUG MSG:\nFirst successful submit completed, no more contact form submissions are allowed unless the page is refereshed") }
  }


  //Function when submit button is pressed
  const handleSubmit = async (e) => {
    e.preventDefault()

    //Set Alert/Modal to true
    setOpenModal(true)

    //Vars for contact form data that we will put into JSON format for AWS Lambda endpoint
    const data = { name, email, address, phone, prefContact, emergContact }

    if (debug == true) {
      console.log("DEBUG MSG:\nBody of request: \n    " + JSON.stringify(data))
      console.log("DEBUG MSG:\nEndpoint: \n    " + Lambda_Endpoint)
    }


    //Try the fetch on AWS Lambda endpoint
    try {
      const fetchPromise = await fetch(Lambda_Endpoint, {
        method: 'POST',
        cache: 'no-cache',
        body: JSON.stringify(data),
        headers: new Headers({ 'content-type': 'application/json' }),
      })

      const response = await fetchPromise.json()

      if (debug == true) {
        console.log("DEBUG MSG:\nInitial results from fetch(): \n    " + response)
      }

      //Check to see if result was successful, if so then request was completed
      if (response.$metadata.httpStatusCode != 200) {
        if (debug == true) {
          console.log("DEBUG MSG:\nError thrown due to response header != \"Success\" \n\n" + "Response result: " + (JSON.parse(response)).toString())
        }
        throw Error(response);
      }

      //Set the Alert values, reset contact form
      setAlertSeverity_Good_Reset()


      if (debug == true) {
        console.log("DEBUG MSG:\nResponse from AWS Lambda endpoint: \n  " + JSON.stringify(response) + "\nPatient Intake Form values: \n  Name: " + name + "\n  Email: " + email + "\n  address: " + address + "\n  phone: " + phone + "\n  Preferred Contact: " + prefContact + "\n  Emergency Contact: " + emergContact)
      }

      // If issues during fetch, catch here and print in console, update the Alert message as well
    } catch (error) {
      console.log("ERROR MSG:\n   " + error);
      setAlertSeverity_Error()
    }
  };



  //Function for when reCAPTCHA object changes, primarily when user completes reCAPTCHA is the submit button avail unless forceVisibleSubmit is enabled
  function onChange(value) {
    if (successfulSubmit == false) {
      { handleClick() }
      if (debug == true) { console.log("DEBUG MSG:\nhandleClick() function called, indicating a change in the reCAPTCHA object") }
    }
    if (debug == true) { console.log("DEBUG MSG:\nCaptcha value:", value) }
  };


  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        //margin: '2vw',
      }}
    >

      <Modal
        open={openModal}
        onClose={handleModalClose}
      >
        <Alert
          severity={severityVal} sx={{ fontSize: "1.2rem" }}>{alertContent}</Alert>
      </Modal>

      <Box component="fieldset"
        sx={{
          display: "flex",
          flexDirection: "column",
          //alignItems: "right",
          maxWidth: 600,
          minWidth: 330,
          width: "80vw",
          mx: "auto",
          p: 2,
          border: "1px solid  #000000",
          borderRadius: "12px",
          //boxShadow: 1,
          fontFamily: 'fontFamily',
          variant: "h4",
          textAlign: { xs: 'center', sm: 'center', md: 'center', lg: 'left' },
          mb: 2,
          fontSize: { xs: '1.5rem', sm: '2rem', md: '2.75rem', lg: '3rem' },
        }}
      >
        {/* <legend >Contact Us</legend> */}
        <Typography variant="h4" align="center" mb={2}
          fontSize={{
            xs: '1.5rem', sm: '2rem', md: '2.75rem', lg: '3rem'
          }}
        >
          Patient Intake Form
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label={nameLabel}
            value={name}
            onChange={(e) => setName(e.target.value)}
            margin="normal"
            required
            inputProps={{ maxLength: 255 }} //maximum character limit on name
          />
          <TextField
            fullWidth
            label={emailLabel}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            margin="normal"
            required
            type="email"
            inputProps={{ maxLength: 255 }} //maximum character limit on email
          />
          <TextField
            fullWidth
            label={addressLabel}
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            margin="normal"
            required
            rows={4}
            inputProps={{ maxLength: 255 }} //maximum character limit on subject
          />
          <TextField
            fullWidth
            label={phoneLabel}
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            margin="normal"
            required
            rows={4}
            inputProps={{ maxLength: 255 }} //maximum character limit on phone
          />
          <TextField
            fullWidth
            label={prefContactLabel}
            value={prefContact}
            onChange={(e) => setPrefContact(e.target.value)}
            margin="normal"
            required
            multiline
            rows={4}
            inputProps={{ maxLength: 255 }} //maximum character limit on preferred contact
          />
          <TextField
            fullWidth
            label={emergContactLabel}
            value={emergContact}
            onChange={(e) => setEmergContact(e.target.value)}
            margin="normal"
            required
            multiline
            rows={4}
            inputProps={{ maxLength: 255 }} //maximum character limit on emerg Contact
          />
          {hide && (
            <Button
              //onClick={handleOpen}
              id="submit-button"
              fullWidth
              type="submit"
              sx={{
                mt: 2,
                backgroundColor: "#000",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#111",
                },
              }
              }
            >
              Submit
            </Button>
          )}
          <Box
            sx={{ margin: "20px" }}
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={reCAPTCHA_Site_Key}
              onChange={onChange}
              size="normal"
            />
          </Box>
        </form>
      </Box>
    </Box>
  );
}




function PatientInformation() {
  const [open, setOpen] = useState(false);
  //const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOpen = () => {
    setOpen(true)
    window['scrollTo']({ top: 0, behavior: 'smooth' })
  };

  return (
    <Box
      sx={{
        margin: 'auto',
        justifyContent: 'center',
        padding: '50px',
        //height: '90vh',
        maxWidth: '1700px',
        minHeight: 'calc(100vh - 406px)',
      }}>
      <Box
        sx={{ padding: '20px', }}>

        <Typography
          sx={{
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: { xs: 19, sm: 19, md: 22, lg: 25, xl: 28, xxl: 32, xxxl: 36 },
            //position: { xs: 'static', sm: 'static', md: 'static', lg: 'fixed' },
            //minHeight: '400px',
            bottom: '50vh'
          }}>
          If you are a new patient, please open the Patient Intake Form below and submit to register. <br /><br /> If you are an existing patient, please <Link href="/contact">contact us</Link> for any questions!<br />
        </Typography>
      </Box>
      <Box
        sx={{
          margin: '80px 0px 80px 0px',
          textAlign: 'center',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Button
          variant='contained'
          onClick={handleOpen}
          size='large'
        >Patient Intake Form</Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{
            //backdropFilter: "blur(1px)", 
            //backgroundColor: "rgb(255, 255, 255)", 
            position: 'absolute',
            overflow: 'auto',
            height: '100vh',
            display: 'block'
          }}
        >
          <Box
            sx={{
              margin: 'auto',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '40px',
              marginBottom: { xs: '100px', sm: '100px', md: '40px' },
              backgroundColor: 'secondary.main',
              width: '85%',
              maxWidth: '850px',
              minWidth: '370px'
              //maxHeight: '100%',
            }}>
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                margin: 'auto',
                //minWidth: '380px',
                justifyContent: 'center',
                fontFamily: 'fontFamily',
                //width: { md: '100vw', lg: '50vw' }
              }}>
              {patientIntakeForm()}
            </Box>
            <Box
              sx={{
                display: 'flex',
                margin: 'auto',
                alignItems: 'center',
                justifyContent: 'center',
                paddingTop: '30px'
              }}>
              <Button
                variant='contained'
                onClick={handleClose}
              >Close</Button>
            </Box>
          </Box>
        </Modal>
      </Box>
    </Box>
  );
}

export default PatientInformation;
export const layout = SpacerLayout;
export const navIndex = 4;
