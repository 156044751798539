import { TextField, Typography, Box, Stack, Button, Alert, Modal } from '@mui/material';
import React from 'react';
import { SpacerLayout } from '../components/Layout';
import ReCAPTCHA from "react-google-recaptcha";
import { useState } from "react";




// Input parameters 
//       If testing, remember to revert setting back to default debug=false and disable_reCAPTCHA=false

// This flag allows the submit button to be visible without reCAPTCHA verification. Only enable for local testing purposes
const forceVisibleSubmit = false
//Debug flag to print out additional debug messages to browser console. Only enable for local testing purposes
const debug = false


//Google reCAPTCHA site key
// Normal/Non-invisible 
//const reCAPTCHA_Site_Key = "6LfuYbEnAAAAAACNqPq18GfYKXoB3IdymG3B28SZ"
// Invisible Key
const reCAPTCHA_Site_Key = "6LchIcYnAAAAACEOi0jBonkBNKu_Wz2Qs5jCHKVp"
//AWS Lambda API Endpoint
const Lambda_Endpoint = process.env.LAMBDA_ENDPOINT_CONTACT;






// Contact form object that creates a contact form on webpage and sends request out to AWS Lambda endpoint
function ContactForm() {

  //Variables for contact form field
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [subject, setSubject] = useState("")
  const [message, setMessage] = useState("")

  //reCAPTCHA reference
  const recaptchaRef = React.createRef()

  //Variables for hiding submit button, set to forceVisibleSubmit var for debug purpose
  const [hide, setHide] = useState(forceVisibleSubmit)

  //Var for only allowing submit button for first submit
  const [successfulSubmit, setSuccessfulSubmit] = useState(false)

  //Vars for Contact form labels, will change these after first successful submit
  const [nameLabel, setNameLabel] = useState("Name");
  const [emailLabel, setEmailLabel] = useState("Email");
  const [subjectLabel, setSubjectLabel] = useState("Subject")
  const [messageLabel, setMessageLabel] = useState("Message")

  //Vars for Alert/Modal: info bar appears with information about the contact form send
  const [openModal, setOpenModal] = useState(false)
  const [alertContent, setAlertContent] = useState('Sending...')
  const [severityVal, setSeverityVal] = useState('info')
  const handleModalClose = () => setOpenModal(false)
  const handleClick = () => { setHide(prev => !prev) }


  //Function for Alert/Modal when error occurs
  function setAlertSeverity_Error() {
    setAlertContent("Apologies, looks like there was an issue! Please try emailing us at justin@tensegritymarin.com instead.")
    setSeverityVal('error')
  }

  //Function for Alert/Modal when fetch is successful. Also sets contact form to "Message Sent" and will permanently disable the submit button until refreshed. This is to prevent excessive contact form sends.
  function setAlertSeverity_Good_Reset() {
    setAlertContent("Message Sent!")
    setSeverityVal('success')
    setName("")
    setEmail("")
    setSubject("")
    setMessage("")
    setHide(prev => !prev)
    setSuccessfulSubmit(true)
    setNameLabel("")
    setEmailLabel("")
    setSubjectLabel("")
    setMessageLabel("Message Sent!")
    if (debug == true) { console.log("DEBUG MSG:\nFirst successful submit completed, no more contact form submissions are allowed unless the page is refereshed") }
  }


  //Function when submit button is pressed
  const handleSubmit = async (e) => {
    e.preventDefault()

    //Set Alert/Modal to true
    setOpenModal(true)

    //Vars for contact form data that we will put into JSON format for AWS Lambda endpoint
    const data = { name, subject, message, email }

    //Try the fetch on AWS Lambda endpoint
    try {
      const fetchPromise = await fetch(Lambda_Endpoint, {
        method: 'POST',
        cache: 'no-cache',
        body: JSON.stringify(data),
        headers: new Headers({ 'content-type': 'application/json'}),
      })

      const response = await fetchPromise.json()

      if (debug == true) {
        console.log("DEBUG MSG:\nInitial results from fetch(): \n    " + response)
      }

      //Check to see if result was successful, if so then request was completed
      if (response.$metadata.httpStatusCode != 200) {
        if (debug == true) {
          console.log("DEBUG MSG:\nError thrown due to response header != \"Success\" \n\n" + "IF statement result: " + (JSON.parse(response)).toString())
        }
        throw Error(response);
      }

      //Set the Alert values, reset contact form
      setAlertSeverity_Good_Reset()


      if (debug == true) {
        console.log("DEBUG MSG:\nResponse from AWS Lambda endpoint: \n  " + JSON.stringify(response) + "\nContact Form values: \n  Name: " + name + "\n  Email: " + email + "\n  Subject: " + subject + "\n  Message: " + message)
      }

      // If issues during fetch, catch here and print in console, update the Alert message as well
    } catch (error) {
      console.log("ERROR MSG:\n   " + error);
      setAlertSeverity_Error()
    }
  };



  //Function for when reCAPTCHA object changes, primarily when user completes reCAPTCHA is the submit button avail unless forceVisibleSubmit is enabled
  function onChange(value) {
    if (successfulSubmit == false) {
      { handleClick() }
      if (debug == true) { console.log("DEBUG MSG:\nhandleClick() function called, indicating a change in the reCAPTCHA object") }
    }
    if (debug == true) { console.log("DEBUG MSG:\nCaptcha value:", value) }
  };


  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        //margin: '2vw',
      }}
    >

      <Modal
        open={openModal}
        onClose={handleModalClose}
      >
        <Alert 
        severity={severityVal} sx={{fontSize: "1.2rem"}}>{alertContent}</Alert>
      </Modal>

      <Box component="fieldset"
        sx={{
          display: "flex",
          flexDirection: "column",
          //alignItems: "right",
          maxWidth: 600,
          minWidth: 330,
          width: "80vw",
          mx: "auto",
          p: 2,
          border: "1px solid  #000000",
          borderRadius: "12px",
          //boxShadow: 1,
          fontFamily: 'fontFamily',
          variant:"h4",
          textAlign:{ xs: 'center', sm: 'center', md: 'center', lg: 'left'},
          mb:2,
          fontSize:{xs: '1.5rem', sm: '2rem', md: '2.75rem', lg: '3rem'},
        }}
      >
        {/* <legend >Contact Us</legend> */}
        <Typography variant="h4" align="center" mb={2}
          fontSize={{
            xs: '1.5rem', sm: '2rem', md: '2.75rem', lg: '3rem'
          }}
        >
          Contact Us
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label={nameLabel}
            value={name}
            onChange={(e) => setName(e.target.value)}
            margin="normal"
            required
            inputProps={{ maxLength: 255 }} //maximum character limit on name
          />
          <TextField
            fullWidth
            label={emailLabel}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            margin="normal"
            required
            type="email"
            inputProps={{ maxLength: 255 }} //maximum character limit on email
          />
          <TextField
            fullWidth
            label={subjectLabel}
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            margin="normal"
            required
            rows={4}
            inputProps={{ maxLength: 255 }} //maximum character limit on subject
          />
          <TextField
            fullWidth
            label={messageLabel}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            margin="normal"
            required
            multiline
            rows={4}
            inputProps={{ maxLength: 1024 }} //maximum character limit on message
          />
          {hide && (
            <Button
              //onClick={handleOpen}
              id="submit-button"
              fullWidth
              type="submit"
              sx={{
                mt: 2,
                backgroundColor: "#000",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#111",
                },
              }
              }
            >
              Submit
            </Button>
          )}
          <Box
            sx={{ margin: "20px" }}
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={reCAPTCHA_Site_Key}
              onChange={onChange}
              size="normal"
            />
          </Box>
        </form>
      </Box>
    </Box>
  );
}




//Contact page
function Contact() {
  return (
    <Stack
      sx={{
        //flexDirection: 'row',
        display: 'flex',
        margin: 'auto',
        minHeight: '90vh',
        backgroundColor: 'secondary.main',
        maxWidth:'2000px',
        //height: 'calc(100vh - 406px)',
        //minHeight: '850px'
        //minHeight: { xs: '65vh', sm: '65vh', md: '75vh', lg: '80vh' },
        //marginTop: { xs: '0', sm: '0', md: '0', lg: '0' },
      }}
      direction={{ xs: 'column-reverse', sm: 'column-reverse', md: 'column-reverse', lg: 'row' }}
      >
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          margin: 'auto',
          justifyContent: 'center',
          paddingTop: '25px',
          paddingBottom: '25px'
        }}
      >
        {ContactForm()}
        </Box>

        <Box
              sx={{
                display: 'flex',
                flexGrow: 1,
                //alignItems: 'center',
                //justifyContent: 'center',
                margin: 'auto',
                //minHeight: '70vh',
                //border: "2px solid  #000000",
                //borderRadius: "12px",
                padding: '15px',
              }}>

        <Typography
          variant="h3"
          fontSize={{
            xs: '1.2rem',
            sm: '1.7rem',
            md: '1.9rem',
            lg: '2.1rem',
            xl: '2.3rem',
            xxl: '2.6rem',
          }}
            
          sx={{
            //padding: '30px 0px 60px 0px',
            lineHeight: '1.5',
            letterSpacing: '1px',
            //margin: '200px 0px 150px 0px',
            paddingTop: '25px',
            paddingBottom: '25px',
            margin: 'auto',
            textAlign: { xs: 'center', sm: 'center', md: 'center', lg: 'left' },
            //textAlign: 'left',
            //wordBreak: "break-word"
          }}
        >
          Email:<br/> justin@tensegritymarin.com
          <br/><br/>
          Phone:<br/> (415)726-7696
          <br/><br/>
          Fax:<br/> (415)295-7247
          <br/><br/>
          Address:<br/>2020 4th Street Unit A<br />
          San Rafael, CA 94901 
        </Typography>
        
      </Box>

    </Stack>

  );
}

export default Contact;
export const layout = SpacerLayout;
export const navIndex = 2;